import {endpoints} from './endpoints'
import request from '../request'

export function newConversation(data){
	return new Promise((resolve) => {
		request({
			url: endpoints.newConversation,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			});
	})
}

export function newMessageConversation(data){
	return new Promise((resolve) => {
		request({
			url: endpoints.newMessageConversation,
			method: 'post',
			data
		})
			.then(function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			});
	})
}