<template>
  <div>
    <Header />
    <v-container style="margin-top: 100px; text-align: justify">
      <v-row align-center justify-center>
        <v-col cols="12" class="align-center justify-center">
          <h3 class="text-center text-primary mb-5">
            Política de Privacidad de la Aplicación para Móviles “IDUAM”.
          </h3>
          <p>
            Esta Aplicación la opera y se encuentra desarrollada por
            <span class="bold">NATURALPHONE S.A.</span>, la cual básicamente es
            una Aplicación de interacción con las personas, con el fin de que
            éstas puedan acceder a tener un monitoreo de su salud a distancia e
            incluso con la posibilidad de recibir atención médica en forma
            virtual y en tiempo real. Una vez instalada y utilizada la
            Aplicación <span class="bold">“IDUAM”</span>, podrán llegarle
            diferentes notificaciones algunas de las cuales le solicitarán
            recabar información sobre Usted y otras en donde se le enviará
            información sobre diferentes aspectos tanto de su propia salud, como
            así también, de información de la salud a nivel general, o bien, de
            beneficios que Usted podrá obtener con el uso y mantención de esta
            Aplicación en su Móvil personal. Todo lo indicado usted declara
            aceptar y consentir. Esta política de privacidad explica cómo se
            utiliza la información recabada a través de nuestra Aplicación y
            Sitio WEB
            <a target="_blank" href="http://www.iduam.com"
              >http://www.iduam.com</a
            >
            y cómo contactarnos si tiene cualquier duda o pregunta.
          </p>
          <p>
            Si accede a esta Aplicación o la utiliza a través de un Sitio WEB
            operado por un tercero, este también podrá recabar información sobre
            Usted, lo cual usted acepta y autoriza. Si Usted baja la Aplicación
            <span class="bold">“IDUAM”</span> a su smartphone, significa que
            Usted acepta tanto los Términos y Condiciones de uso, como así
            también, la Política de Privacidad de
            <span class="bold">“IDUAM”</span>, lo cual, asimismo, declara por el
            presente acto expresamente. Si Usted no está de acuerdo tanto con
            nuestros Términos y Condiciones, como con nuestra Política de
            Privacidad, Usted sencillamente, no debe operar la Aplicación
            <span class="bold">“IDUAM”</span> y los servicios que le ofrece la
            referida Aplicación, como así también, de su Sitio WEB
            <a target="_blank" href="http://www.iduam.com"
              >http://www.iduam.com</a
            >.
          </p>
          <p>
            Asimismo y en consecuencia a lo anteriormente indicado, la empresa
            le informa que los datos personales que se obtengan a través de la
            aplicación <span class="bold">“IDUAM”</span> y/o Portal WEB serán
            transferidos a <span class="bold">NATURALPHONE S.A.</span> y serán
            tratados con la finalidad de enviarle, entre otros, publicidad o
            cualquier tipo de comunicación sobre información de salud. Al
            aceptar la presente Política de Privacidad, Usted autoriza,
            asimismo, expresamente la mencionada transferencia de datos
            personales y el tratamiento de los mismos con dicha finalidad.
          </p>

          <h4 class="text-primary">
            1. ¿Qué tipo de información podemos Solicitar?.
          </h4>
          <p>
            En alguna ocasión y con el fin de que Usted obtenga diferentes
            beneficios por interactuar y mantener activa en su smartphone
            nuestra Aplicación <span class="bold">“IDUAM”</span>, podremos
            solicitarle información como su nombre, nombre de usuario, dirección
            de correo electrónico, edad, RUT o DNI, sexo, etc. Al brindarnos
            dichos datos Usted autoriza su utilización para fines promocionales,
            entre otros. Asimismo, podremos enviarle información sobre productos
            o servicios que a Usted le interese conocer sobre salud a nivel
            general, o bien, de medicamentos, tratamientos, etc, e incluso
            información de nuevos servicios que a futuro sean incorporados en la
            Aplicación <span class="bold">“IDUAM”</span>. Adicionalmente,
            nuestra Aplicación como su Sitio WEB, pueden incluir encuestas
            opcionales donde se le pregunte por sus opiniones acerca de
            distintos productos o servicios de salud, como así también, de cómo
            Usted mejoraría nuestra Aplicación
            <span class="bold">“IDUAM”</span> y su Sitio WEB
            <a target="_blank" href="http://www.iduam.com"
              >http://www.iduam.com</a
            >.
          </p>

          <h4 class="text-primary">
            2. ¿Qué hacemos con la información que Obtenemos?.
          </h4>
          <p>La información que obtenemos, la utilizamos de distintos modos:</p>
          <ul>
            <li>
              Para atender las peticiones del Servicio de Atención al Cliente.
            </li>
            <li>
              Para análisis interno e investigación con el fin de mejorar
              nuestra Aplicación y Portal WEB.
            </li>
            <li>
              Para enviarle información de sobre productos, servicios o
              beneficios que a usted le podrían interesar.
            </li>
            <li>
              Para enviarle correos electrónicos administrativos (por ejemplo,
              si usted olvida su contraseña).
            </li>
            <li>
              Para prevenir y detectar fraudes o utilización ilegítima de
              nuestra Aplicación y Sitio WEB.
            </li>
            <li>
              Para realizar Encuestas y Promociones, o bien, enviar noticias o
              informaciones que le podrían interesar.
            </li>
          </ul>
          <p>
            Usted autoriza que los datos personales que proporcione serán
            utilizados también para los fines anteriormente indicados, aceptando
            dicho tratamiento.
          </p>

          <h4 class="text-primary">
            3. ¿Cómo y cuándo utilizamos tu ubicación?
          </h4>
          <p>
            Sólo obtendremos tu ubicación bajo consentimiento previo y en las
            siguientes situaciones:
          </p>
          <ul>
            <li>
              Al utilizar (abrir) la aplicación, siempre y cuando hayas activado
              la opción de ser monitoreado por nuestro panel de operaradores,
              compartiremos la ubicación con el panel Iduam para que los
              médicos/operadores puedan comunicarse contigo.
            </li>
            <li>
              Al generar una emergencia de salud enviaremos notificaciones al
              panel de operadores y a tu red de seguridad, para estas
              notificaciones solicitamos tu ubicación para entregarla a las
              personas que te puedan ayudar.
            </li>
          </ul>

          <h4 class="text-primary">
            4. ¿Quién más tiene acceso a su Información?.
          </h4>
          <p>
            Nuestras empresas afiliadas, distribuidores regionales y otros
            organismos que trabajen con nosotros o que hayan generado Acuerdos
            Comerciales con nuestra Empresa pueden tener acceso a su
            información, como por ejemplo Municipios, Gobierno, Clínicas,
            Hospitales, Isapres, Fonasa, Laboratorios, etc, con los cuales
            podríamos tener un convenio de colaboración a futuro para la
            explotación de la Aplicación y Sitio WEB
            <span class="bold">“IDUAM”</span>, con el fin de gestionarla en
            nuestro nombre y proporcionarle a Usted la información y los
            servicios que Usted haya solicitado, o bien, que le queramos
            brindar, lo cual usted acepta y brinda consentimiento a efectos de
            que sus datos personales sean remitidos y/o transferidos a estas
            empresas u organismos, así como el tratamiento que realicen sobre
            los mismos.
          </p>

          <p>
            Algunas de estas empresas u organismos, podrían procesar su
            información en países que estén fuera de la zona económica europea
            (EEA), como Estados Unidos y Japón. La legislación de protección de
            datos de estos países podría no ofrecer el mismo nivel de protección
            que la de la EEA. Si no desea que transfiramos sus datos de este
            modo, no acceda a nuestros Sitio WEB o Aplicación
            <span class="bold">“IDUAM”</span>, ni los utilice.
          </p>
          <p>
            En circunstancias excepcionales, podríamos revelar sus datos
            personales por mandato legal, como por ejemplo: frente actividades
            ilegales o perjudiciales. En caso de venta o reestructuración de
            nuestro negocio, sus datos podrán transferirse como parte del mismo,
            lo cual Usted acepta y autoriza.
          </p>
          <p>
            También trabajamos con empresas de terceros para ayudar a mejorar y
            a comprender el uso de nuestra Aplicación <span class="bold">“IDUAM”</span> y su Sitio WEB,
            para ofrecer así la mejor experiencia posible para Usted. Estas
            empresas generan información de uso estadístico y están autorizadas
            a usar su información personal solo en la medida de proporcionarnos
            estos servicios, lo cual Usted acepta y autoriza.
          </p>
        </v-col>
      </v-row>
    </v-container>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/commons/Header.vue";
import Footer from "../components/commons/Footer.vue";

export default {
  name: "Politicas",
  components: { Header, Footer },
  data: () => ({}),
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.bold {
  font-weight: 600;
}
ul li {
  list-style-type:circle
}
</style>
