import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VLayout,{staticStyle:{"background-color":"#0064F1","height":"100%"},attrs:{"justify-center":"","align-center":""}},[_c(VSnackbar,{attrs:{"vertical":"","bottom":"","center":"","color":_vm.messageColor},model:{value:(_vm.showMessage),callback:function ($$v) {_vm.showMessage=$$v},expression:"showMessage"}},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.message))])]),_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('h3',{staticClass:"text-center white--text"},[_vm._v("Ingresa a tu cuenta")]),_c(VLayout,{staticStyle:{"width":"100%"},attrs:{"flex-column":"","flex-md-row":"","justify-center":"","align-center":""}},[_c(VImg,{attrs:{"max-height":"300","max-width":"300","src":require('../../assets/logo-iduam.png')}}),_c(VForm,{ref:"form",staticClass:"d-flex flex-column flex-grow-1",staticStyle:{"max-width":"400px","width":"100%"},attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{staticClass:"custom-placeholer-color",attrs:{"rules":_vm.emailRules,"label":"Email","required":"","dark":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c(VTextField,{attrs:{"rules":_vm.passwordRules,"label":"Contraseña","dark":"","required":"","append-icon":_vm.passType ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.passType ? 'password' : 'text'},on:{"click:append":() => (_vm.passType = !_vm.passType)},model:{value:(_vm.userPassword),callback:function ($$v) {_vm.userPassword=$$v},expression:"userPassword"}}),_c(VBtn,{staticClass:"mr-4",attrs:{"disabled":!_vm.valid,"color":"success"},on:{"click":_vm.loginUser}},[_vm._v(" Ingresar ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }